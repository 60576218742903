<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <h1 class="text-h5 font-weight-bold" ref="special" id="special">{{ $t(`label.locateUs`) }}</h1>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center" align="center" class="my-8 mx-0 full-width locate-us">
      <v-col cols="12" md="12" lg="10" xl="9" class="d-flex align-center flex-column">
        <v-col cols="12" v-for="(info, index) in paginatedOutlet" :key="info.name + index" class="px-0 full-width">
          <v-card class="px-0 rounded-lg d-sm-flex flex-wrap align-center justify-space-between justify-lg-space-around full-width" :elevation="5">
            <v-col cols="12" sm="6" md="4" lg="4">
              <img
                :src="imgError ? '/static/images/unbox-store-outlet.png' : info.storeImage"
                :alt="info.name + ' - Outlet'"
                :title="info.name + ' - Outlet'"
                @error="onImgError()"
                class="full-width"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="5" xl="5">
              <h2 class="pb-2">{{ info.name }}</h2>
              <h4 class="font-weight-regular text-body-2">{{ info.fullAddress }} {{ info.postcode }}&nbsp;{{ info.city }},&nbsp;{{ info.state }}</h4>
              <h4 class="font-weight-regular text-body-2 cursor-pointer d-flex align-center">
                {{ $t(`label.phone`) }}: &nbsp;
                <span @click="callUs(info.phoneCode, info.phone)" class="text-decoration-underline blue--text pr-3">({{ info.phoneCode }}) {{ info.phone }}</span>
                <span>
                  <img
                    @click="whatsappUs(info.phoneCode, info.phone)"
                    height="20px"
                    src="/static/images/footerContactUs/whatsapp-icon.png"
                    alt="WhatsApp"
                    title="WhatsApp | Contact Us"
                    class="d-block"
                  />
                </span>
              </h4>

              <div class="operation-time py-3">
                <li
                  v-for="businessHour in info.operationTime"
                  :key="businessHour.key"
                  class="text-capitalize d-flex justify-space-between font-weight-regular text-body-2"
                  :class="$vuetify.breakpoint.smAndUp ? 'seventy-width' : 'eighty-width'"
                >
                  <p>{{ businessHour.day }}:</p>
                  {{ businessHour.operation_hours[0].start_time }} - {{ businessHour.operation_hours[0].end_time }}
                </li>
              </div>
              <div class="font-weight-regular text-body-2 pb-2">{{ $t(`label.servicesAvailable`) }} &nbsp; {{ info.operateDesc }}</div>
              <h5 class="cursor-pointer font-weight-light caption pb-3" @click="openUrl(info.googleReviewUrl)" v-html="$t(`label.reviewOutlet`)"></h5>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="3" xl="3" class="full-width">
              <div v-html="info.mapUrl"></div>

              <v-col cols="12" class="d-block d-md-flex align-end justify-space-between full-width pa-0">
                <v-col cols="12" sm="12" md="7" lg="7" class="pa-0 d-none">
                  <app-button :title="$t('action.getDirection')" :action="() => openActionSheet(info)" type="default" height="40px" maxWidth="100%" customClass="ma-auto" />
                </v-col>
                <v-col cols="5" sm="3" md="4" lg="4" class="d-flex align-end justify-center pa-0 ma-auto">
                  <v-col cols="6" sm="6" md="6" lg="6" class="pa-1" id="">
                    <img src="/static/images/commonAppLogo/google-maps.png" class="full-width cursor-pointer" @click="openWithApp('maps', info)" />
                  </v-col>
                  <v-col cols="6" sm="6" md="6" lg="6" class="pa-1 pt-2">
                    <img src="/static/images/commonAppLogo/waze.png" class="full-width cursor-pointer" @click="openWithApp('waze', info)" />
                  </v-col>
                </v-col>
              </v-col>
            </v-col>
          </v-card>
        </v-col>
        <v-col cols="12" class="get-direction-modal" :class="showOptions ? 'd-flex' : 'd-none'">
          <div class="text-center actionsheet-overlay" @click="showOptions = !showOptions"></div>
          <div class="actionsheet pa-3">
            <div class="pa-3 text-body-2 font-weight-medium">{{ $t(`label.openWith`) }}</div>
            <ul class="pa-3 pb-0">
              <li v-for="tile in mapTiles" :key="tile.title" @click="closeActionOpenApp(tile.url)" class="pb-5">
                <img :src="tile.img" :alt="tile.title" />
                <p class="pl-5 ma-0">{{ tile.title }}</p>
              </li>
            </ul>
          </div>
        </v-col>
        <!-- <v-col cols="12">
          <v-pagination color="unbox_primary" v-model="filter.pageNumber" :length="outletListPagination.lastPage" :total-visible="10"></v-pagination>
        </v-col> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { LOOKUPS_OUTLET_OUTLETS } from '@/store/lookup.module'
export default {
  name: 'locateUs',
  data: () => ({
    filter: {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'asc',
      selectedPage: 1
    },
    imgError: false,
    showOptions: false,
    mapTiles: [
      { img: '/static/images/commonAppLogo/google-maps.png', title: 'Maps' },
      { img: '/static/images/commonAppLogo/waze.png', title: 'Waze' }
    ],
    selectedLongitude: 0,
    selectedLatitude: 0,
    destination: ''
  }),
  async created() {
    await this.getOutlets()
  },
  methods: {
    async getOutlets() {
      await this.$store.dispatch(LOOKUPS_OUTLET_OUTLETS)
    },
    onImgError() {
      this.imgError = true
    },
    openUrl(link, target) {
      if (target == undefined) target = '_self'
      window.open(link, target)
    },
    whatsappUs(phoneCode, phone) {
      let whatsapp = phoneCode.replace('+', '') + phone
      this.openUrl('https://wa.me/' + whatsapp)
    },
    callUs(phoneCode, phone) {
      let callnum = phoneCode.replace('+', '') + phone
      this.openUrl('tel:' + callnum)
    },
    openWithApp(type, info) {
      this.destination = info.latitude + ',' + info.longtitude
      if (type == 'maps') {
        this.openUrl('https://www.google.com/maps/search/?api=1&query=' + this.destination)
      } else {
        this.openUrl('https://waze.com/ul?ll=' + this.destination + '&navigate=yes&z=10')
      }
    },
    closeActionOpenApp(url) {
      this.openUrl(url)
    },
    openActionSheet(info) {
      this.destination = info.latitude + ',' + info.longtitude
      this.mapTiles[0].url = 'https://www.google.com/maps/search/?api=1&query=' + this.destination
      this.mapTiles[1].url = 'https://waze.com/ul?ll=' + this.destination + '&navigate=yes&z=10'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          this.showOptions = !this.showOptions
          break
        case 'md':
        default:
          this.showOptions = false
          break
      }
    },
    polishBusinessHour(businessHour) {
      let endTime,
        startTime,
        day,
        time,
        dayConcat = ''
      endTime = businessHour[0].operation_hours[0].end_time
      startTime = businessHour[0].operation_hours[0].start_time
      let desc = ''
      let arr = []
      for (let i = 0; i < businessHour.length; i++) {
        if (businessHour[i].day == 'public holiday') {
          let temp =
            '<li class="text-capitalize d-flex justify-space-between font-weight-regular text-body-2 "><p>' +
            (dayConcat != '' ? dayConcat : day) +
            ':</p>' +
            time +
            '</li><li class="text-capitalize d-flex justify-space-between font-weight-regular text-body-2"><p>' +
            businessHour[i].day +
            ':</p>' +
            businessHour[i].operation_hours[0].start_time +
            ' - ' +
            businessHour[i].operation_hours[0].end_time +
            '</li>'
          desc += temp
          arr.push({ day: day, time: time }, { day: businessHour[i].day, time: businessHour[i].operation_hours[0].start_time + ' - ' + businessHour[i].operation_hours[0].end_time })
        } else {
          if (businessHour[i].operation_hours[0].start_time == startTime && businessHour[i].operation_hours[0].end_time == endTime) {
            day = businessHour[i].day
            time = businessHour[i].operation_hours[0].start_time + ' - ' + businessHour[i].operation_hours[0].end_time
            dayConcat = dayConcat + (dayConcat == '' ? '' : ', ') + day
          } else {
            let temp = '<li class="text-capitalize d-flex justify-space-between font-weight-regular text-body-2 "><p>' + (dayConcat != '' ? dayConcat : day) + ':</p>' + time + '</li>'
            desc += temp
            arr.push({ day: dayConcat != '' ? dayConcat : day, time: time })
            dayConcat = ''
            endTime = businessHour[i].operation_hours[0].end_time
            startTime = businessHour[i].operation_hours[0].start_time
            day = businessHour[i].day
            time = businessHour[i].operation_hours[0].start_time + ' - ' + businessHour[i].operation_hours[0].end_time
            dayConcat = dayConcat + (dayConcat == '' ? '' : ', ') + day
          }
        }
      }

      return desc
    }
  },
  computed: {
    outlets() {
      // return this.$store.state.lookup.outlets
      return this.$store.state.lookup.outlets.filter((x) => x.mapUrl)
    },
    paginatedOutlet() {
      let temp = this.outlets
      let paginatedArr = []

      let firstIndex = this.filter.pageNumber * this.filter.pageSize - this.filter.pageSize
      let lastIndex = this.filter.pageNumber * this.filter.pageSize - 1
      if (this.outletListPagination.total - lastIndex > 0) {
        for (let i = firstIndex; i <= lastIndex; i++) {
          paginatedArr.push(temp[i])
        }
      } else {
        for (let i = firstIndex; i < this.outletListPagination.total; i++) {
          paginatedArr.push(temp[i])
        }
      }
      return paginatedArr
    },
    outletListPagination() {
      return {
        pageNumber: this.filter.pageNumber,
        pageSize: 10,
        lastPage: Math.ceil(this.outlets.length / 10),
        tableRowsCounts: 10,
        total: this.outlets.length
      }
    }
  },
  mounted() {
    if (this.$route.query.src == 'app') {
      let header = document.querySelector('#unboxHeader')
      let footer = document.querySelector('#unboxFooter')
      header.style.display = 'none'
      footer.style.display = 'none'
    }
  }
}
</script>
<style lang="scss" scoped>
.operation-time {
  li {
    height: 20px;
    list-style: none;
  }
}

.get-direction-modal {
  position: fixed;
  top: 0;
  height: 100%;
  padding: 0;
  z-index: 100;

  .actionsheet-overlay {
    opacity: 0.46;
    background-color: #212121;
    border-color: #212121;
    width: 100%;
    height: 100%;
  }
  .actionsheet {
    background-color: #ffffff;
    opacity: 1;
    position: absolute;
    bottom: 0;
    width: 100%;

    ul {
      li {
        list-style: none;
        display: flex;
        align-items: center;

        img {
          width: 45px;
        }
      }
    }
  }
}
</style>
